<template>
    <sign-layout class="sign-in-page" :processing="processing" :error="error">

        <template #default>
            <div class="sign-in-page__content">

                <h3 class="sign-in-page__title">Login</h3>

                <sign-in-form
                    class="sign-in-page__form"
                    :disabled="processing"
                    @setProcessing="setProcessing"
                    @setError="setError"
                />

                <p>
                    <router-link class="sign-in-page__alternative" :to="{name: 'forgot-pass'}">
                        Forgot password?
                    </router-link>
                </p>
            </div>
        </template>

    </sign-layout>
</template>

<script>
import SignLayout from '@/layouts/sign.vue'
import SignInForm from './form.vue'

export default {
    components: {
        SignLayout,
        SignInForm,
    },
    data() {
        return {
            processing: false,

            error: null,
        }
    },
    methods: {
        setProcessing(value) {
            this.processing = value
        },

        setError(error = null) {
            this.error = error
        },
    },
}
</script>

<style lang="scss">
</style>
