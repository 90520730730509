<template>
    <app-form class="form--sign-in">

        <app-input
            class="form__input form__input--username"

            v-model="auth.login"

            label="Username"
            type="email"
            :icon="'email'"

            :error="errors.fields.login"
            :disabled="disabled"

            @change="showNextError('login')"
        />

        <app-input
            class="form__input form__input--pass"

            v-model="auth.pass"

            label="Password"
            type="password"
            :icon="'lock'"

            :error="errors.fields.pass"
            :disabled="disabled"

            @change="showNextError('pass')"
        />

        <div class="form__btns">
            <button class="form__button btn" :disabled="disabled" @click.prevent="signIn">Sign in</button>
        </div>
    </app-form>
</template>

<script>
import appInput from '@/components/app-input-sign'
import appForm from '@/components/app-form'

import UserDetailsValidator from '@/validators/user-details-validator'

export default {
    components: {
        appInput,
        appForm,
    },

    props: {
        disabled: { type: Boolean, default: false },
    },

    data() {
        return {
            auth: {
                login: process.env.VUE_APP_TEST_USER_EMAIL || '',
                pass:  process.env.VUE_APP_TEST_PASSWORD || '',
            },

            errors: {
                fields: {},
            },

        }
    },

    methods: {
        signIn() {
            if (this.validation()) {
                this.$emit('setProcessing', true)

                this.$emit('setError', null)

                this.$store.dispatch('LoginEmailPassword', this.auth).then(() => {
                    this.$emit('setProcessing', false)

                    this.$router.push({ name: 'dashboard' })
                })
                .catch(error => {
                    this.$emit('setError', error)

                    this.$emit('setProcessing', false)
                })
            }
        },

        validation() {
            let is_valid = true

            this.errors = { fields: {} }
            this.$emit('setError', null)

            const values = {
                login: {
                    value: this.auth.login,
                    name: 'Username'
                },

                pass: {
                    value: this.auth.pass,
                    message: '* Required field',
                },
            }

            for (const key in values) {
                if (UserDetailsValidator.isRuleExists(key)) {
                    if (UserDetailsValidator.isInvalid(key, values[key].value, values[key])) {
                        this.errors.fields[ key ] = UserDetailsValidator.getError()

                        is_valid = false
                    }
                }
            }

            return is_valid

            // return !this.showNextError()
        },

        showNextError(except) {
            if (except) {
                delete this.errors.fields[ except ]
            }
        },
    },
}
</script>

<style lang="scss">
</style>